import { Container, Section } from "../components/layout";

import { Link } from "react-router-dom";
import { Meta } from "../components/meta";
import { Title } from "../components/typography";
import styled from "styled-components";

export const Page404 = () => {
  return (
    <>
      <Meta title="Error" />
      <MainError>
        <Section>
          <Container width={70}>
            <Title number={1} />
            <Link to="/">Something bad happened! Click Me to Go Home.</Link>
          </Container>
        </Section>
      </MainError>
    </>
  );
};

export default Page404;

const MainError = styled.main`
  background-size: cover;
  background-position: bottom left;
  position: absolute;
  padding: 4rem 4rem;
  background-color: white;
  height: 100vh;
  width: 100%;
  z-index: 1000;

  * {
    margin: 0;
    padding: 0;
  }
`;
