import ReactLoading from "react-loading";
import { Text } from "../typography";
import { colors } from "../../assets/styles/parameters";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Loader = () => {
  const variants = {
    start: {
      opacity: 1,
    },
    end: {
      opacity: 0,
    },
  };
  return (
    <>
      <BaseLoader
        variants={variants}
        animate="start"
        initial="start"
        exit="end"
      >
        <ReactLoading
          type="spinningBubbles"
          color={colors.white}
          height={50}
          width={50}
        />
        <Text color="white" text="Loading" />
      </BaseLoader>
    </>
  );
};

export default Loader;

const BaseLoader = styled(motion.section)`
  background: ${colors.green};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.2;

  & > p {
    color: white;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
`;
