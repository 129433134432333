import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "galleryapi/api/v1/galleries";

const INITIALSTATE = {
  data: [],
  project: {},
  isSuccess: false,
  errMessage: "",
  loading: true,
};

export const FetchGallery = createAsyncThunk(
  "gallery/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchGalleryProject = createAsyncThunk(
  "gallery/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const galleryReducer = createSlice({
  name: "gallery",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchGallery.pending]: (state) => {
      state.loading = true;
    },
    [FetchGallery.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchGallery.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchGalleryProject.pending]: (state) => {
      state.loading = true;
    },
    [FetchGalleryProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.project = action.payload;
      state.isSuccess = true;
    },
    [FetchGalleryProject.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
  },
});

export default galleryReducer.reducer;
