import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "testimonialapi/api/v1/testimonial/";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};
export const CreateTestimony = createAsyncThunk(
  "testimony/create",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.post(`${BASEPATH}`, args);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchTestimonies = createAsyncThunk(
  "testimony/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/fetchAllFeatured`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const testimonySlice = createSlice({
  name: "testimony",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchTestimonies.pending]: (state) => {
      state.loading = true;
    },
    [FetchTestimonies.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchTestimonies.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },

    [CreateTestimony.pending]: (state) => {
      state.loading = true;
    },
    [CreateTestimony.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.created = true;
      state.errMessage = action.payload;
    },
    [CreateTestimony.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
  },
});

export default testimonySlice.reducer;
