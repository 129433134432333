import { Helmet } from "react-helmet";

export const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>
        {title} | Extreme Clean Environment Cleaning Services | Landscape
        Maintenance and Design, Pressure Washing, and Cleaning and Sanitizing
        Services | DC, Maryland, & Virginia{" "}
      </title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keyword" content={keywords} />}
      {title && <meta property="og:title" content={`${title} | Edaston`} />}
      {description && <meta property="og:description" content={description} />}
      {title && <meta name="twitter:title" content={`${title} | Edaston`} />}
      {description && <meta name="twitter:description" content={description} />}
    </Helmet>
  );
};

Meta.defaultProps = {
  title:
    "Extreme Clean Environment Cleaning Services | Landscape Maintenance and Design, Pressure Washing, and Cleaning and Sanitizing Services | DC, Maryland, & Virginia",
  description:
    "Extreme Clean is a veteran and family-owned and operated company specializing in professional landscape maintenance and design, pressure washing, and cleaning and sanitation services in Washington, DC, Maryland, and Virginia.",
};
