import { useDispatch, useSelector } from "react-redux";

import CTABanner from "../../elements/cta/CTABanner";
import { FetchService } from "../../redux/ServiceSlice";
import { Loader } from "../../components/loader";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import ServiceDescription from "../../elements/services/ServiceDescription/ServiceDescription";
import ServiceGallery from "../../elements/services/ServiceGallery/ServiceGallery";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const ServiceDetailPage = () => {
  const { service, loading } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  let params = useParams();

  useEffect(() => {
    dispatch(FetchService(params.slug));
  }, [dispatch, params.slug]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Meta title={`${service.title && service.title}`} />
      <PageHeader
        backgroundImage={
          service.media &&
          service.media.mediaFiles[0] &&
          service.media.mediaFiles[0].url &&
          service.media.mediaFiles[0].url
        }
      />
      <ServiceDescription data-aos="fade-up" service={service} />
      <ServiceGallery
        data-aos="fade-up"
        media={service.media && service.media}
      />
      <CTABanner data-aos="fade-up" />
    </>
  );
};

export default ServiceDetailPage;
