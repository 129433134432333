import { useDispatch, useSelector } from "react-redux";

import CTABanner from "../../elements/cta/CTABanner";
import { FetchGallery } from "../../redux/GallerySlice";
import { GalleryHero } from "../../elements/gallery/GalleryHero/GalleryHero";
import { ImagesList } from "../../elements/gallery/ImagesList/ImagesList";
import { Loader } from "../../components/loader";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import { useEffect } from "react";

export const Gallery = () => {
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(FetchGallery());
  }, [dispatch]);

  if (loading && data === []) {
    return <Loader />;
  }

  return (
    <>
      <Meta title="Gallery" />
      <PageHeader title="Gallery" />
      <GalleryHero data-aos="fade-in" data={data} />
      <ImagesList data-aos="fade-in" data={data} />
      <CTABanner data-aos="fade-up" />
    </>
  );
};

export default Gallery;
