import Wrapper from "./ServiceGallery.styled";

const ServiceGallery = ({ media, ...props }) => {
  const mediaFile = () => {
    return (
      media &&
      media.mediaFiles &&
      media.mediaFiles
        .filter((s, i) => i < 6)
        .map((s, i) => {
          return (
            <img
              key={i}
              src={s.url}
              alt="Watering Plant"
              width={340}
              height={350}
            />
          );
        })
    );
  };

  return (
    <Wrapper {...props} className="spaced-section">
      <div className="container">
        <div className="grid">{mediaFile()}</div>
      </div>
    </Wrapper>
  );
};

export default ServiceGallery;
