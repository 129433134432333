import { Grid, Section } from "../../components/layout";

import { CardCTA } from "../../components/cards";
import { Text } from "../../components/typography";
import { company } from "../../redux/data";
import parse from "html-react-parser";

const PartnerDescription = ({ description, ...props }) => {
  return (
    <Section>
      <Grid>
        <Text as="div" text={parse(description)} />
        <CardCTA
          version="content"
          title="Partner with Us"
          description="Speak with our team about using Extreme Clean to handle all your
                landscape maintenance and design, pressure washing, and cleaning
                and sanitizing needs."
          path="/contact"
          pathText="Contact Us"
          info={`Call: ${company.phone}`}
        />
      </Grid>
    </Section>
  );
};

export default PartnerDescription;
