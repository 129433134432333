import "flatpickr/dist/themes/material_green.css";

import { BaseLabel, InputContainer } from "./Inputs";
import { Span, Text } from "../typography";

import Flatpickr from "react-flatpickr";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const TimeInput = ({ name, ...props }) => {
  return (
    <InputContainer>
      <BaseLabel style={props.entity && { paddingLeft: "0.5rem" }} {...props}>
        <Text color={colors.gray700} text={props.label} />
        {props.entity && <Span>{props.entity}</Span>}
      </BaseLabel>
      <BaseStyled
        options={{
          enableTime: true,
          noCalendar: true,
          dateFormat: "h:i",
          minuteIncrement: 30,
        }}
        value={props.input.value}
        onChange={props.input.onChange}
        {...props}
      />
    </InputContainer>
  );
};

export const BaseStyled = styled(Flatpickr)`
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0.5px solid ${colors.gray400};
  border-radius: 5px;
  width: 100%;
  outline: none;
  padding: 0.8rem 0.5rem 0.5rem;

  &:hover,
  &:active,
  &:focus {
    border: 0.5px solid ${colors.black};
  }

  &:visited,
  &:active,
  &:focus,
  &:default {
    background-color: white;
  }

  &::placeholder {
    font-size: 0.8rem;
    color: ${colors.gray400};
  }
`;
