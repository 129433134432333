import { colors, responsiveness, shadow } from "../../assets/styles/parameters";
import { useEffect, useState } from "react";

import { Button } from "../button";
import { Text } from "../typography";
import styled from "styled-components";

export const CookieBanner = () => {
  const [show, setShow] = useState(true);

  const onHandleShow = () => {
    setShow(false);

    localStorage.setItem(
      "Extreme",
      "By using Extreme Environmental Cleaning Services, I accept that the website useses essential cookies to make it work.."
    );
  };

  useEffect(() => {
    if (localStorage.getItem("Extreme")) {
      setShow(false);
    }
  }, [show]);

  return (
    <BaseContainer show={show}>
      <div>
        <Text
          scale="sm"
          text={<>We use some essential cookies to make this website work.</>}
        />
      </div>
      <Button onClick={onHandleShow} varient="primary" text="Accept" />
    </BaseContainer>
  );
};

export default CookieBanner;

const BaseContainer = styled.div`
  display: ${({ show }) => {
    return show ? "flex" : "none";
  }};

  flex-direction: space-between;
  justify-content: center;
  align-items: center;
  box-shadow: ${shadow.dark};

  position: fixed;
  bottom: 2%;
  left: 25%;

  background: ${colors.white};
  color: ${colors.black};

  border-radius: 2px;
  z-index: 100;
  width: 50%;
  padding: 1rem 3rem;
  cursor: pointer;
  transition: all 1s ease;
  margin: auto;

  div {
    margin-right: 1rem;

    a,
    p {
      color: ${colors.black};
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  & > button {
    margin-left: 1rem;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 2rem 1rem;
  }
`;
