import {
  AboutPage,
  BookingPage,
  ContactPage,
  FAQPage,
  Gallery,
  GalleryDetailPage,
  GalleryPage,
  Home,
  Page404,
  PartnershipDetailPage,
  PartnershipPage,
  Partnerships,
  ServiceDetailPage,
  Services,
  ServicesPage,
  Testimony,
  TestimonyCreate,
  ThankYouPage,
} from "../pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { CookieBanner } from "../components/banner";
import Layout from "../components/layout/Layout";
import { Loader } from "../components/loader";
import ScrollToTop from "../utils/ScrollToTop";
import { Suspense } from "react";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Layout>
        <ScrollToTop />
        <CookieBanner />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="services" element={<ServicesPage />}>
              <Route index element={<Services />} />
              <Route path=":slug" element={<ServiceDetailPage />} />
            </Route>
            <Route path="partners" element={<PartnershipPage />}>
              <Route index element={<Partnerships />} />
              <Route path=":slug" element={<PartnershipDetailPage />} />
            </Route>
            <Route path="gallery" element={<GalleryPage />}>
              <Route index element={<Gallery />} />
              <Route path=":id" element={<GalleryDetailPage />} />
            </Route>
            <Route path="blog" element={<GalleryPage />}>
              <Route index element={<Gallery />} />
              <Route path=":slug" element={<PartnershipDetailPage />} />
            </Route>
            <Route path="faq" element={<FAQPage />} />
            <Route path="book-now" element={<BookingPage />} />
            <Route
              path="success/thank-you/booking"
              element={<ThankYouPage />}
            />
            <Route path="contact" element={<ContactPage />} />
            <Route path="testimonials" element={<Testimony />} />
            <Route path="testimonials/submit" element={<TestimonyCreate />} />
            <Route path="*" element={<Page404 />} />
          </Routes>{" "}
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRoutes;
