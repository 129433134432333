import styled, { css } from "styled-components";

import { AppLinks } from "../links";
import { colors } from "../../assets/styles/parameters";

export const Button = ({
  version,
  varient,
  size,
  path,
  text,
  ...otherProps
}) => {
  switch (version) {
    case "link":
      return (
        <StyledButton varient={varient} size={size} {...otherProps}>
          <AppLinks to={path} {...otherProps}>
            {text}
          </AppLinks>{" "}
        </StyledButton>
      );
    default:
      return (
        <StyledButton size={size} varient={varient} {...otherProps}>
          {text}
        </StyledButton>
      );
  }
};

const BaseButton = styled.button`
  &,
  &:link,
  &:visited {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    width: max-content;
    transition: 1s all ease;
    margin-bottom: 1rem;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.65;
    cursor: auto;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;

const Varients = ({ varient }) => {
  switch (varient) {
    case "outline-green":
      return css`
        background: transparent;
        color: ${colors.green};
        border: 1px solid ${colors.green};
      `;
    case "outline-wh":
      return css`
        background: transparent;
        border: 1px solid white;
      `;
    case "primary":
      return css`
        background: ${colors.green};
        border: 1px solid white;
        color: white;

        a {
          color: white;
        }
      `;
    default:
      return css`
        background: ${colors.blue};
        border: 1px solid ${colors.blue};
        color: white;
        a,
        p {
          color: white;
        }
      `;
  }
};

const Sizes = ({ size }) => {
  switch (size) {
    default:
      return css`
        padding: 0.5rem 2rem;
      `;
  }
};

const Shape = ({ shape }) => {
  switch (shape) {
    case "circle":
      return css`
        border-radius: 50%;
        padding: 0.75rem 1rem;
      `;
    default:
      return css`
        border-radius: 5px;
      `;
  }
};

const StyledButton = styled(BaseButton)`
  ${Sizes}
  ${Varients}
  ${Shape}
`;
