import ContactBanner from "../../elements/contact/ContactBanner";
import ContactForm from "../../elements/contact/ContactForm";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import img from "../../assets/imgs/front_yard_curb_appeal.jpg";

export const ContactPage = () => {
  return (
    <>
      <Meta title="Contact Us" />
      <PageHeader title="Contact Us" backgroundImage={img} />
      <ContactForm data-aos="fade-up" />
      <ContactBanner img={img} />
    </>
  );
};

export default ContactPage;
