import { Container, Section } from "../../components/layout";

import { Link } from "react-router-dom";
import { Title } from "../../components/typography";
import styled from "styled-components";

const ContactBanner = ({ ...props }) => {
  return (
    <Wrapper {...props}>
      <Container width="70" margin="auto">
        <div className="book-now">
          <Title number={2}>Ready to start a project?</Title>
          <Link to="/book-now" className="btn">
            Request an Estimate
          </Link>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ContactBanner;

const Wrapper = styled(Section)`
  padding: 6rem;
  background-color: #f9f9f9;
  text-align: center;
`;
