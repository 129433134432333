import { Container, Grid, Section } from "../../components/layout";
import {
  CustomRatingInput,
  CustomSelect,
  CustomTextArea,
  Input,
} from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { CreateTestimony } from "../../redux/TestimonySlice";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import { Title } from "../../components/typography";
import img from "../../assets/imgs/front_yard_curb_appeal.jpg";
import { states } from "../../redux/data";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

const TestimonyForm = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { isSuccess, errMessage, created } = useSelector(
    (state) => state.testimony
  );

  const onFormSubmit = (values) => {
    const newValues = {
      ...values,
      client: `${values.client}, ${values.city}, ${values.state}`,
    };

    if (newValues) {
      dispatch(CreateTestimony(newValues));
    }

    if (created && isSuccess) {
      swal("Good job!", "You testimony was successfully sent.", "success").then(
        navigate("/testimonials")
      );
    }

    if (!isSuccess && errMessage) {
      swal("Oops!", "Something bad happened. Try Again!", "error").then(
        window.location.reload()
      );
    }
  };

  return (
    <Form
      onSubmit={onFormSubmit}
      validate={(formValues) => {
        const errors = {};
        if (!formValues.rating) {
          errors.rating = "Rating required";
        }
        if (!formValues.client || formValues.client.trim().length === 0) {
          errors.client = "Name required";
        }
        if (!formValues.city || formValues.city.trim().length === 0) {
          errors.city = "City required";
        }
        if (!formValues.state || formValues.state.trim().length === 0) {
          errors.state = "State required";
        }
        if (!formValues.message || formValues.message.trim().length === 0) {
          errors.message = "Testimony required";
        }
        return errors;
      }}
    >
      {({ handleSubmit, pristine, submitting, invalid, ...otherProps }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Field
              name="rating"
              component={CustomRatingInput}
              label="Overall Rating"
            />
            <Field
              name="client"
              type="text"
              component={Input}
              label="Name"
              placeholder="Enter your name..."
              entity="&#10029;"
              required
            />

            <Grid>
              <Field
                name="city"
                type="text"
                component={Input}
                label="City"
                placeholder="Enter your city..."
                entity="&#10029;"
              />
              <div>
                <Field
                  name="state"
                  label="State"
                  component={CustomSelect}
                  entity="&#10029;"
                >
                  <option />
                  {Object.values(states).map((s) => {
                    return <option value={s}>{s}</option>;
                  })}
                </Field>
              </div>
            </Grid>
            <Field
              label="Provide your testimony"
              name="message"
              type="text"
              component={CustomTextArea}
              placeholder="Enter your testimony..."
              entity="&#10029;"
            />
            <div>
              <button
                className="btn"
                type="submit"
                disabled={
                  submitting || pristine || invalid || !otherProps.values.client
                }
                text="Complete form."
              >
                {submitting ? "Submit Testimony....." : "Submit Testimony"}
              </button>
            </div>
          </form>
        </>
      )}
    </Form>
  );
};

export const TestimonyCreate = () => {
  return (
    <>
      <Meta title="Submit Testimonial" />
      <PageHeader title="Submit Testimony" backgroundImage={img} />
      <Section>
        <Container width="50" margin="auto">
          <Title number={4}>
            Thank you for working with us. We'd love to get your feedback.
          </Title>
          <TestimonyForm />
        </Container>
      </Section>
    </>
  );
};
