import { Container, Grid, Section } from "../../components/layout";
import { CreateMessage, resetState } from "../../redux/ContactSlice";
import { CustomTextArea, Input } from "../../components/forms";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { Title } from "../../components/typography";
import { company } from "../../redux/data";
import styled from "styled-components";
import swal from "sweetalert";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const { isSuccess, errMessage } = useSelector((state) => state.contact);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    if (values) {
      dispatch(CreateMessage(values));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      swal("Good job!", "Message sent to Extreme Clean", "success").then(
        dispatch(resetState()),
        navigate("/")
      );
    }

    if (!isSuccess && errMessage) {
      swal("Oops!", "Something bad happened. Try Again!", "error").then(
        window.location.reload()
      );
    }
  }, [navigate, dispatch, isSuccess, errMessage]);

  return (
    <Wrapper>
      <div>
        <Title number={2}>We'd love to hear from you!</Title>
        <p>
          <a href={`tel:${company.phone}`}>{company.phone}</a> |{" "}
          <a href={`mailto:${company.email}`}>{company.email}</a>
        </p>
      </div>
      <Container width={50} margin="auto">
        <Form
          onSubmit={onSubmit}
          validate={(formValues) => {
            const errors = {};
            if (
              !formValues.firstName ||
              formValues.firstName.trim().length === 0
            ) {
              errors.firstName = "First name required";
            }
            if (
              !formValues.lastName ||
              formValues.lastName.trim().length === 0
            ) {
              errors.lastName = "Last name required";
            }
            if (!formValues.email || formValues.email.trim().length === 0) {
              errors.email = "Email address required";
            } else if (
              formValues.email &&
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                formValues.email
              )
            ) {
              errors.email = "Invalid email address";
            }
            if (!formValues.message || formValues.message.trim().length === 0) {
              errors.message = "A message required";
            }
            return errors;
          }}
        >
          {({ handleSubmit, pristine, submitting, invalid, dirtyFields }) => (
            <form onSubmit={handleSubmit}>
              <Grid>
                <Field
                  name="firstName"
                  type="text"
                  component={Input}
                  label="First Name"
                  placeholder="First name..."
                  entity="&#10029;"
                />
                <Field
                  name="lastName"
                  type="text"
                  component={Input}
                  label="Last Name"
                  placeholder="Last name..."
                  entity="&#10029;"
                />
              </Grid>

              <Field
                name="organization"
                type="text"
                component={Input}
                label="Organization"
                placeholder="Organization name..."
              />

              <Grid>
                <Field
                  label="Phone Number"
                  name="phone"
                  type="phone"
                  component={Input}
                  placeholder="Phone number..."
                />
                <Field
                  label="Email"
                  name="email"
                  type="email"
                  component={Input}
                  placeholder="Email address..."
                  entity="&#10029;"
                />
              </Grid>

              <Field
                label="How can we be helpful to you?"
                name="message"
                type="text"
                component={CustomTextArea}
                placeholder="Your message..."
                entity="&#10029;"
              />
              <div>
                <button
                  className="btn"
                  type="submit"
                  disabled={submitting || pristine || invalid}
                  text="Enter your details and message."
                >
                  {!submitting ? "Send Message" : "Sending Message..."}
                </button>
              </div>
            </form>
          )}
        </Form>
      </Container>
    </Wrapper>
  );
};

export default ContactForm;

const Wrapper = styled(Section)`
  margin-top: 6rem;

  & > div:first-child {
    text-align: center;
  }
`;
