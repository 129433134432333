import img from "../../assets/imgs/extreme_clean_logo.png";
import styled from "styled-components";

const Logo = ({ width = 100, height = 50 }) => {
  return (
    <Wrapper width={width} height={height}>
      <img src={img} alt="Extreme Clean Enviromental Services Logo" />
    </Wrapper>
  );
};
export default Logo;

const Wrapper = styled.div`
  position: relative;
  width: ${(props) => `${props.width}px`};
  max-width: 100%;
  height: ${(props) => `${props.height}px`};
  margin: 0 auto 1rem;
`;
