import styled, { css } from "styled-components";

import { responsiveness } from "../../../assets/styles/parameters";

const Container = ({ width, margin, padding, children }) => {
  return (
    <BaseContainer width={width} margin={margin} padding={padding}>
      {children}
    </BaseContainer>
  );
};

export default Container;

const BaseContainer = styled.div`
  padding: ${({ padding }) => (padding ? `${padding}rem` : "2rem")};
  width: ${({ width }) => (width ? `${width}%` : "100%")};

  ${({ margin }) => {
    switch (margin) {
      case "auto":
        return css`
          margin: 0 auto;
        `;
      default:
        return css`
          margin: 0;
        `;
    }
  }};

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
    padding: 2rem 0rem;
  }
`;
