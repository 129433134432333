import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FetchPartners } from "../../../redux/PartnerSlice";
import { FetchServices } from "../../../redux/ServiceSlice";
import { IoChevronDownOutline } from "react-icons/io5";
import { closeSidebar } from "../../../redux/AppSlice";
import { useEffect } from "react";

export const MainNavLinks = () => {
  const { services, partners } = useSelector((state) => state);
  const router = useLocation();
  const dispatch = useDispatch();

  const dispatchCloseSidebar = () => dispatch(closeSidebar());

  useEffect(() => {
    dispatch(FetchServices());
    dispatch(FetchPartners());
  }, [dispatch]);

  return (
    <ul className="nav-list">
      <li className="nav-item">
        <Link
          to="/"
          className={router.pathname === "/" ? "active" : ""}
          onClick={dispatchCloseSidebar}
        >
          Home
        </Link>
      </li>
      <li className={`nav-item  has-dropdown`}>
        <Link
          to="/services"
          className={router.pathname === "/services" ? "active" : ""}
          onClick={dispatchCloseSidebar}
          state={{ services: services.data }}
        >
          Services <IoChevronDownOutline />
        </Link>

        <div className="dropdown">
          <ul>
            {services.data.map((service, index) => {
              const serviceName = service.title
                .toLowerCase()
                .replace(/\s+/g, "-");
              return (
                <li key={index}>
                  <Link
                    to={`/services/${serviceName}`}
                    state={{ service: service }}
                  >
                    {service.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </li>

      <li className={`nav-item  has-dropdown`}>
        <Link
          to="/testimonials"
          className={router.pathname === "/testimonials" ? "active" : ""}
          onClick={dispatchCloseSidebar}
          state={{ services: services.data }}
        >
          Testimonials <IoChevronDownOutline />
        </Link>

        <div className="dropdown">
          <ul>
            <li>
              <Link to="/testimonials">View all Testimonials</Link>
            </li>
            <li>
              <Link to="/testimonials/submit">Submit Testimonial</Link>
            </li>
          </ul>
        </div>
      </li>
      <li className="nav-item">
        <Link
          to="/gallery"
          className={router.pathname === "/gallery" ? "active" : ""}
          onClick={dispatchCloseSidebar}
        >
          Gallery
        </Link>
      </li>
      <li className={`nav-item  has-dropdown`}>
        <Link
          to="/partners"
          className={router.pathname === "/partners" ? "active" : ""}
          onClick={dispatchCloseSidebar}
        >
          Partners <IoChevronDownOutline />
        </Link>

        <div className="dropdown">
          <ul>
            {partners.data.map((partner, index) => {
              const partnerName = partner.type
                .toLowerCase()
                .replace(/\s+/g, "-");
              return (
                <li key={index}>
                  <Link
                    to={`/partners/${partnerName}`}
                    state={{ partner: partner, partners: partners }}
                  >
                    {partner.type}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </li>
      <li className={`nav-item  has-dropdown`}>
        <Link
          to="/about"
          className={router.pathname === "/about" ? "active" : ""}
          onClick={dispatchCloseSidebar}
        >
          About <IoChevronDownOutline />
        </Link>

        <div className="dropdown">
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
          </ul>
        </div>
      </li>

      <li className="nav-item">
        <Link
          to="/contact"
          className={router.pathname === "/contact" ? "active" : ""}
          onClick={dispatchCloseSidebar}
        >
          Contact Us
        </Link>
      </li>
      <li className="nav-item  btn" onClick={dispatchCloseSidebar}>
        <Link to="/book-now">Request an Estimate</Link>
      </li>
    </ul>
  );
};

export default MainNavLinks;
