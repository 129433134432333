import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "bookingapi/api/v1/bookings";

const INITIALSTATE = {
  success: false,
  errMessage: "",
  loading: false,
  data: [],
};

export const CreateBooking = createAsyncThunk(
  "bookings/create",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.post(BASEPATH, args);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const bookingReducer = createSlice({
  name: "bookings",
  initialState: INITIALSTATE,
  reducers: {
    resetState: () => INITIALSTATE,
  },
  extraReducers: {
    [CreateBooking.pending]: (state) => {
      state.loading = true;
    },
    [CreateBooking.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.data = action.payload;
    },
    [CreateBooking.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.errMessage = action.payload;
    },
  },
});

export const { resetState } = bookingReducer.actions;
export default bookingReducer.reducer;
