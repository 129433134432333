import styled from "styled-components";

const Wrapper = styled.section`
  .grid {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .testimonial {
    padding: 2rem;
    border-radius: var(--borderRadius);
    transition: var(--transition);

    .body {
      .stars {
        margin-bottom: 1rem;
      }
      .message {
        font-style: italic;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      .user {
        margin-left: 1rem;
        color: #666;
      }
    }

    &:hover {
      background-color: var(--primary-500);
      color: var(--white);
      box-shadow: var(--shadow-2);

      .footer .user {
        color: var(--white);
      }
    }
  }

  @media (min-width: 768px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export default Wrapper;
