import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "orgapi/api/v1/staffs";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};

export const FetchStaffMembers = createAsyncThunk(
  "staff/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchStaff = createAsyncThunk(
  "staff/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const staffReducer = createSlice({
  name: "staff",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchStaffMembers.pending]: (state) => {
      state.loading = true;
    },
    [FetchStaffMembers.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchStaffMembers.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchStaff.pending]: (state) => {
      state.loading = true;
    },
    [FetchStaff.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchStaff.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
  },
});

export default staffReducer.reducer;
