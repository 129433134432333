import "aos/dist/aos.css";

import Aos from "aos";
import CombinedRoutes from "./routes";
import { GlobalStyles } from "./assets/styles/globalstyles";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
  }, []);

  return (
    <>
      <GlobalStyles />
      <CombinedRoutes />
    </>
  );
};

export default App;
