import { Grid, Section } from "../../../components/layout";

import { Title } from "../../../components/typography";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

export const GalleryHero = ({ data, ...props }) => {
  return (
    <Wrapper {...props}>
      <Title>View our lastest works</Title>
      <Grid col={4}>
        {data &&
          data[0] &&
          data[0].media &&
          data[0].media.mediaFiles &&
          data[0].media.mediaFiles
            .filter((s, i) => i < 4)
            .map((s, index) => {
              return <img key={index} src={s.url} alt="Gardening Tools" />;
            })}
      </Grid>
    </Wrapper>
  );
};

export default GalleryHero;

const Wrapper = styled(Section)`
  text-align: center;

  p {
    max-width: 520px;
    margin: 0 auto;
  }

  & > div {
    /* position: relative; */
    /* height: 400px; */
    margin-top: 4rem;

    img {
      height: 200px;
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    & > div {
      img {
        height: 400px;
      }
    }
  }
`;
