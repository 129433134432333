import { colors, fontSize, shadow } from "../../assets/styles/parameters";

import { AppLinks } from "../links";
import { Title } from "../typography";
import styled from "styled-components";

export const Cards = ({
  version,
  img,
  imgDescription,
  title,
  featuredText,
  body,
  path,
  icon,
  action,
  children,
  ...otherProps
}) => {
  switch (version) {
    case "1":
      return (
        <CardOne {...otherProps}>
          <Title number={5}>{title ? title : "Lorem, ipsum dolor."}</Title>
          <div>{children}</div>
        </CardOne>
      );
    case "2":
      return (
        <CardTwo {...otherProps}>
          <div>
            <Title number={6}>{title}</Title>
            {action && action}
          </div>
          <div>{children}</div>
        </CardTwo>
      );
    case "3":
      return (
        <CardThree {...otherProps}>
          <Title number={6}>{title}</Title>
          <div>{children}</div>
        </CardThree>
      );
    default:
      return (
        <CardDefault {...otherProps}>
          {icon}
          {path && <AppLinks to={path}>{title}</AppLinks>}
        </CardDefault>
      );
  }
};

const BaseCard = styled.div`
  padding: 1rem 0 0.5rem 0;
  border-radius: 5px;
  background-color: ${colors.white};
  box-shadow: ${shadow.light};
  transition: all 0.3s ease-in;
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin: 0 0 2rem 0;
  height: ${({ height }) => (height ? "100%" : "max-content")};

  h6 {
    padding: 0 1rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
  }
`;

const CardOne = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  h2 {
    overflow: hidden;
    white-space: nowrap;
  }
`;

const CardTwo = styled(BaseCard)`
  font-size: ${fontSize.small};

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-right: 1rem;

    p {
      padding-right: 1rem;
    }

    a {
      font-size: ${fontSize.nav};
    }
  }
`;

const CardThree = styled(BaseCard)`
  font-size: ${fontSize.small};

  & > div {
    min-height: 10rem;
    overflow-y: scroll;
  }

  h6 {
    text-align: left;
  }
`;

const CardDefault = styled(BaseCard)`
  height: 130px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${colors.blue};
`;
