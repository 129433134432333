import { useDispatch, useSelector } from "react-redux";

import ContactBanner from "../../elements/cta/CTABanner";
import CounterBanner from "../../elements/testimonial/CounterBanner/CounterBanner";
import { FetchTestimonies } from "../../redux/TestimonySlice";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import TestimonialsGrid from "../../elements/testimonial/TestimonialsGrid/TestimonialsGrid";
import { useEffect } from "react";

export const TestimonialPage = () => {
  const { data } = useSelector((state) => state.testimony);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchTestimonies());
  }, [dispatch]);

  return (
    <>
      <Meta title="Testimonials" />
      <PageHeader title="Testimonials" />
      <CounterBanner data-aos="fade-up" data={data} />{" "}
      <TestimonialsGrid data-aos="fade-up" testimonials={data} />
      <ContactBanner data-aos="fade-up" />
    </>
  );
};

export default TestimonialPage;
