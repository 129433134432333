import { Container, Layout, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/button";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import { resetState } from "../../redux/BookingSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export const ThankYouPage = () => {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    if (
      location.state === null ||
      location.state.bookings === null ||
      location.state.bookings.id === undefined
    ) {
      navigate("/book-now");
    }
  }, [location, navigate]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  return (
    <>
      <Meta title="Thank You" />
      <PageHeader />
      <Layout>
        <Section>
          <Container width="80" margin="auto">
            <Title number={2}>
              Thank you for leaving your landscaping and cleaning needs to us.
            </Title>
            <Text
              width={60}
              text="A member of our team will review your request. If you
              have any questions before then, please call us at 301-256-2058 or email
              info@excleanenviro.com."
            />

            <Button version="link" path="/" text="Go to home page" />
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default ThankYouPage;
