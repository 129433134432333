import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "orgapi/api/v1/faqs";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};

export const FetchFAQs = createAsyncThunk(
  "faq/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const FAQSlice = createSlice({
  name: "faq",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchFAQs.pending]: (state) => {
      state.loading = true;
    },
    [FetchFAQs.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchFAQs.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
  },
});

export default FAQSlice.reducer;
