export const HomeRoute = "/";
export const AboutRoute = "/about";
export const GalleryRoute = "/gallery";
export const BookingRoute = "/booking";
export const ContactRoute = "/contact";
export const PartnershipRoute = "/partners";
export const ServicesRoute = "/services";
export const TestimonialRoute = "/testimonial";

export const Path = [
  {
    name: "Home",
    path: HomeRoute,
  },
  {
    name: "Services",
    path: ServicesRoute,
  },
  {
    name: "Partnership",
    path: PartnershipRoute,
  },
  {
    name: "About",
    path: AboutRoute,
  },
  {
    name: "Gallery",
    path: GalleryRoute,
  },
  {
    name: "Booking",
    path: BookingRoute,
  },
  {
    name: "Contact",
    path: ContactRoute,
  },
  {
    name: "Contact",
    path: TestimonialRoute,
  },
];
