import styled from "styled-components";

const Wrapper = styled.section`
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    align-items: center;
    background-color: #b9d9eb;
    border-radius: var(--borderRadius);
    padding: 3rem 2rem;
    text-align: center;

    * {
      margin-bottom: 0;
    }
  }

  .count {
    display: block;
    font-size: calc(var(--h1-size) * 1.3);
    margin-bottom: 0.5rem;
  }

  @media (min-width: 768px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export default Wrapper;
