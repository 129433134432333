/*=============================================
    #Variables
=============================================*/

/**
 * TABLE OF CONTENTS
 *
 * COLORS
 * Brand colors...................These are the main brand colors. They will be used the most
 * Neutral colors.................White and black, gray scale, secondary colors.
 * Utility colors.................Other colors to represent states: info, warning, success, etc.
 *
 * TYPOGRAPHY
 * Font families..................The fonts used in the design system.
 * Font weights...................Font weights
 * Sizes..........................Font sizing for body text, paragraphs, inputs
 * Heading sizes..................Font sizing for page headings
 *
 * BREAKPOINTS
 * Breakpoints....................Global responsive breakpoints definitions.
 *
 * SPACING
 * Spacing........................Global fixed set of spacing variables
 */

/*=============================================
=            Section Colors                  =
=============================================*/

/* Brand, Neutral, Utility Colors */

export const colors = {
  ocblue: "#0065FF",
  white: "#ffffff",
  black: "#000000",
  green: "#47762E",
  greenDark: "#395E25",
  greenDark2: "#2B471C",
  blue: "#51A0CE",
  blueDark: "#4180A5",
  blueDark2: "#31607C",
  gray: "#707070",
  gray100: "#f8f9fa",
  gray200: "#e9ecef",
  gray300: "#dee2e6",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: "#6c757d",
  gray700: "#495057",
  gray800: "#343a40",
  gray900: "#212529",
};

/*=============================================
=            Section Typograph               =
=============================================*/

/* Font Familes */

export const fontFamily = {
  headings: "Hind Siliguri",
  body: "Hind Siliguri",
};

/* Font Sizes */

export const fontSize = {
  def: "18px",
  nav: "14px",
  h1: "4.209rem",
  h2: "3.157rem",
  h3: "2.369rem",
  h4: "1.777rem",
  h5: "1.333rem",
  h6: "1rem",
  small: "0.907rem",
};

/* Line Heights */

export const lineHeight = {
  headings: 1.3,
  body: 1.7,
};

/* Font Weights */

export const fontWeight = 400;

/* Heading Sizes */

/*=============================================
=            Section Breakpoints             =
=============================================*/

/* Layout */

export const GridGap = {
  default: "2rem",
  mobile: "2rem",
};

/* Responsiveness */

export const responsiveness = {
  mobile1: "991px",
  ipadMin: "992px",
  ipadMax: "1199px",
  desktop1: "1200px",
  maxWidth: "1460px",
};

/*=============================================
=            Section Spacing                 =
=============================================*/

/* Spacing */

export const border = {
  transparent: "1px solid transparent",
  white: `1px solid ${colors.white}`,
  black: `1px solid ${colors.black}`,
  gray: `1px solid ${colors.gray}`,
  gold: `1px solid ${colors.gold}`,
};

/*=====  End of Section comment block  ======*/

/*=============================================
=            Effects            =
=============================================*/

export const shadow = {
  light: "0 5px 15px rgba(0, 0, 0, 0.1)",
  dark: "0 5px 15px rgba(0, 0, 0, 0.3)",
};

/*=====  End of Effects  ======*/
