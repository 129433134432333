import { useDispatch, useSelector } from "react-redux";

import CTABanner from "../../elements/cta/CTABanner";
import { FetchServices } from "../../redux/ServiceSlice";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import ServicesList from "../../elements/services/ServicesList/ServicesList";
import { useEffect } from "react";

export const Services = () => {
  const { data } = useSelector((state) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchServices());
  }, [dispatch]);

  return (
    <>
      <Meta title="Services" />
      <PageHeader data-aos="fade-in" title="Services" />
      <ServicesList data-aos="fade-up" services={data} />
      <CTABanner data-aos="fade-up" />
    </>
  );
};

export default Services;
