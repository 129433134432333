import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSidebarOpen: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
      document.body.classList.add("overflow-hidden");
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
      document.body.classList.remove("overflow-hidden");
    },
  },
});

export const { openSidebar, closeSidebar } = appSlice.actions;
export default appSlice.reducer;
