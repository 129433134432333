import { Text, Title } from "../typography";
import { colors, responsiveness } from "../../assets/styles/parameters";

import { AppLinks } from "../links";
import styled from "styled-components";

export const CardCTA = ({
  version,
  title,
  description,
  path,
  pathText,
  info,
}) => {
  switch (version) {
    case "content":
      return (
        <BaseCard>
          <div>
            <Title number={4}>{title}</Title>
            <Text text={description} />
          </div>
          <div>
            <Text text={info} />
          </div>
        </BaseCard>
      );
    default:
      return (
        <BaseCard>
          <div>
            <Title number={4}>{title}</Title>
            <Text text={description} />
          </div>
          <div>
            <AppLinks to={path}>{pathText}</AppLinks>
          </div>
        </BaseCard>
      );
  }
};

export default CardCTA;

const BaseCard = styled.div`
  background-color: #e7e8ed;
  border-radius: 5px;
  height: min-content;
  width: 350px;
  justify-self: end;

  & > div:first-child {
    padding: 2rem 1.5rem;

    p {
      padding: 0;
      margin: 2rem 0 0;
      font-size: 1.3rem;
    }
  }

  & > div:last-child {
    background-color: var(--secondary-100);
    padding: 1.5rem;
    text-align: center;

    a,
    p {
      color: ${colors.white};
      font-size: 1.5rem;
      padding: 0;
      margin: 0;
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
  }
`;
