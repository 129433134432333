import { Container, Grid, Section } from "../../components/layout";
import { useDispatch, useSelector } from "react-redux";

import { Accordion } from "../../components/accordion";
import { FetchFAQs } from "../../redux/FAQSlice";
import { Loader } from "../../components/loader";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import { Title } from "../../components/typography";
import { useEffect } from "react";

export const FAQPage = () => {
  const { data, loading } = useSelector((state) => state.faq);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchFAQs());
  }, [dispatch]);

  return (
    <>
      <Meta title="FAQs" />
      <PageHeader title="FAQs" />
      <Section>
        <Grid col="l14">
          <Title>Have a question about our services?</Title>
          <Container width={70} margin="auto">
            {loading ? <Loader /> : <Accordion data={data} />}
          </Container>
        </Grid>
      </Section>
    </>
  );
};

export default FAQPage;
