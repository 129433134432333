import { useDispatch, useSelector } from "react-redux";

import CTABanner from "../../elements/cta/CTABanner";
import { FetchGalleryProject } from "../../redux/GallerySlice";
import { GalleryList } from "../../elements/gallery/GalleryList/GalleryList";
import { Loader } from "../../components/loader";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const GalleryDetailPage = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { project, loading } = useSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(FetchGalleryProject(id));
  }, [dispatch, id]);

  if (loading || !project) {
    return <Loader />;
  }

  return (
    <>
      <Meta title={`Gallery | ${project.name && project.name}`} />
      <PageHeader />
      <GalleryList data-aos="fade-up" data={project && project} />
      <CTABanner data-aos="fade-up" />
    </>
  );
};

export default GalleryDetailPage;
