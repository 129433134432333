import { Container, Grid, Section } from "../../../components/layout";
import { Text, Title } from "../../../components/typography";

import styled from "styled-components";

export const GalleryItem = (props) => {
  const { url, title } = props;

  return <img src={url} alt={title} />;
};

export const GalleryList = ({ data, ...props }) => {
  const images = data && data.media && data.media.mediaFiles;

  return (
    <Wrapper {...props}>
      <Container width="60" margin="auto">
        <Title number={2}>{data.name}</Title>
        <Text text={data.description} />
      </Container>

      <Container>
        <Grid col={3}>
          {images && images.map((item, i) => <GalleryItem key={i} {...item} />)}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default GalleryList;

const Wrapper = styled(Section)`
  div:first-child {
    text-align: center;
  }
  img {
    border-radius: var(--borderRadius);
    height: 300px;
  }
`;
