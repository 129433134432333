import { Text, Title } from "../../typography";
import { colors, responsiveness } from "../../../assets/styles/parameters";

import altImg from "../../../assets/imgs/corporate_ext.jpg";
import styled from "styled-components";

const PageHeader = ({ backgroundImage, title, description, ...otherProps }) => {
  return (
    <Wrapper
      {...otherProps}
      className="spaced-section"
      background={backgroundImage ? backgroundImage : altImg}
    >
      <div>
        <Title number={1}>{title}</Title>
        {description && <Text text={description} />}
      </div>
    </Wrapper>
  );
};

export default PageHeader;

const Wrapper = styled.header`
  background-color: gray;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  height: 55vh;
  display: flex;
  padding: 6rem 6rem 3rem 5rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.7;
  }

  * {
    color: var(--white);
  }

  div {
    color: ${colors.white};
    width: 70%;
    align-self: flex-end;
    z-index: 10;
  }

  h1 {
    font-size: var(--h1-size);
    z-index: 100;
  }

  p {
    font-size: var(--h4-size);
  }

  @media screen and (min-width: ${responsiveness.maxWidth}) {
    div {
      width: ${responsiveness.maxWidth};
      margin: 0 auto;
    }
  }
  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
    padding: 1.5rem;
  }
`;
