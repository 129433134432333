import { colors, fontFamily, fontWeight, lineHeight } from "./parameters";

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
*, 
*::before, 
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 1.2;
}
html{
  font-size: 100%;
  line-height: 1.15;
  scroll-behavior: smooth;

}

body{
    background: ${colors.white};
    font-family: ${fontFamily.body};
    line-height: ${lineHeight.body};
    font-weight: 200;
    overflow: auto;
    overflow-x: hidden;
    color: var(--textColor);
}

h1, h2, h3, h4, h5, h6{
    margin: 0 0 0.8rem 0;
    font-family: ${fontFamily.headings};
    font-weight: ${fontWeight};
    line-height: ${lineHeight.headings};
    font-weight: 400;

}


h1{
    margin-top: 0;
}

p {
  margin: 0;
  margin-bottom: 1.5rem;
}


a{
    text-decoration: none;
    color: ${colors.black}
}

ol, li, ul{
    list-style: none;
}

img{
    width: 100%;
}


select {
   appearance: none;   
}


main {
  display: block;
}


a {
  background-color: transparent;
}



abbr[title] {
  border-bottom: none; 
  text-decoration: underline; 
  text-decoration: underline dotted; 
}


b,
strong {
  font-weight: bolder;
}


small {
  font-size: 80%;
}



sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}



button{
  overflow: visible;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; 
  padding: 0; 
}


[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[hidden] {
  display: none;
}


:root {
  font-size: 15px;
  /* colors */
  --secondary-100:#51A0CE;
  --primary-100: #ecf8d9;
  --primary-200: #d6f1b6;
  --primary-300: #acd587;
  --primary-400: #7eac5d;
  --primary-500: #47762e;
  --primary-600: #346521;
  --primary-700: #245417;
  --primary-800: #17440e;
  --primary-900: #0d3808;

  --success-100: #dbfbd3;
  --success-200: #b1f8a8;
  --success-300: #7bea79;
  --success-400: #55d55e;
  --success-500: #25ba3e;
  --success-600: #1b9f3d;
  --success-700: #12853b;
  --success-800: #0b6b37;
  --success-900: #075933;

  --warning-100: #fff8cc;
  --warning-200: #ffef99;
  --warning-300: #ffe466;
  --warning-400: #ffd93f;
  --warning-500: #ffc700;
  --warning-600: #dba600;
  --warning-700: #b78600;
  --warning-800: #936900;
  --warning-900: #7a5400;

  --error-100: #ffe1d3;
  --error-200: #ffbba9;
  --error-300: #ff8e7e;
  --error-400: #ff635d;
  --error-500: #ff2833;
  --error-600: #db1d37;
  --error-700: #b71439;
  --error-800: #930c37;
  --error-900: #7a0736;

  /* grey */
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;
  
  /* rest of the colors */
  --black: #000;
  --white: #fff;

  /* fonts  */
  --small-text: 0.875rem;
  --extra-small-text: 0.7em;
  --textFont: 'Mukta Vaani', -apple-system, BlinkMacSystemFont, sans-serif;
  --headingFont: 'Mukta Vaani', -apple-system, BlinkMacSystemFont, sans-serif;
  --base-font-size: 1rem;
  --scale: 1.2;
  --p-size: var(--base-font-size);
  --h6-size: calc(var(--base-font-size) * var(--scale));
  --h5-size: calc(var(--h6-size) * var(--scale));
  --h4-size: calc(var(--h5-size) * var(--scale));
  --h3-size: calc(var(--h4-size) * var(--scale));
  --h2-size: calc(var(--h3-size) * var(--scale));
  --h1-size: calc(var(--h2-size) * var(--scale));
  /* rest of the vars */
  --backgroundColor: var(--white);
  --textColor: #040404;
  --borderRadius: 0.35rem;
  --letterSpacing: 1px;
  --transition: 0.2s ease-in-out all;
  --max-width: 1460px;
  --fixed-width: 500px;
  --fluid-width: 90vw;
  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

body,
p,
ul,
ol {
  font-size: var(--p-size);
  line-height: 1.5;
}

body.overflow-hidden,
body.modal-open {
  overflow: hidden !important;
}


.big-text {
  font-size: var(--h5-size);
}

.title-text {
  line-height: 1.15;
  font-size: var(--h4-size);
  font-weight: 500;
  font-family: var(--headingFont);
}

h1 {
  font-size: var(--h1-size);
}

h2 {
  font-size: var(--h2-size);
}

h3 {
  font-size: var(--h3-size);
}

h4 {
  font-size: var(--h4-size);
}

h5 {
  font-size: var(--h5-size);
}

h6 {
  font-size: var(--h6-size);
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--primary-400);
}

.underlined {
  text-decoration: underline;
}

a,
button,
.btn {
  cursor: pointer;
  line-height: 1.15;
}
button {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}
ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img, .img {
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 10px;
}

video {
  display: block;
  width: 100%;
}
small,
.small-text {
  font-size: var(--small-text);
}

.container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}

.full-page {
  min-height: 100vh;
}

section.spaced-section {
  padding: 4rem 0;
}

.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  border-radius: var(--borderRadius);
  background-color: var(--primary-500);
  color: var(--white);
  min-width: 170px;
  padding: 0.75rem;
  font-size: 1.15rem;
  border: 2px solid var(--primary-500);
}

.btn:hover {
  color: var(--white);
  background-color: var(--primary-600);
  border-color: var(--primary-600);
}

.btn-secondary{
  background-color: #51A0CE;
  border: 2px solid #51A0CE;

}

.btn-outline {
  background-color: transparent;
  border-color: var(--primary-500);
  color: var(--primary-500);
}

.btn-outline.white {
  border-color: var(--white);
  color: var(--white);
}
.btn-outline.dark {
  border-color: var(--textColor);
  color: var(--textColor);
}
.btn-outline:hover {
  background-color: transparent;
}

.stars,
.star {
  font-size: 1.25rem;
  color: var(--warning-500);
}

.profile-picture {
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-picture.outlined {
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border: 2px solid var(--white);
}

.card {
  background-color: #e7e8ed;
  border-radius: var(--borderRadius);
  overflow: hidden;
}

.card .body {
  padding: 3rem;
}

.card .footer {
  padding: 1.5rem;
  color: var(--white);
  background-color: #51a0ce;
}
`;
