import { closeSidebar, openSidebar } from "../../../redux/AppSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { FetchComp } from "../../../redux/CompanySlice";
import { FetchServices } from "../../../redux/ServiceSlice";
import { FetchTestimonies } from "../../../redux/TestimonySlice";
import { IoMdClose } from "react-icons/io";
import { IoMenuOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Logo from "../../../elements/Logo/Logo";
import { MainNavLinks } from "./MainNavLinks";
import { colors } from "../../../assets/styles/parameters";
import { company } from "../../../redux/data";
import styled from "styled-components";

const MainNavbar = (props) => {
  const { absolute } = props;

  const [isOffTop, setIsOffTop] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [offsetRight, setOffsetRight] = useState(0);
  const { isSidebarOpen } = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const dispatchOpenSidebar = () => dispatch(openSidebar());
  const dispatchCloseSidebar = () => dispatch(closeSidebar());

  useEffect(() => {
    dispatch(FetchServices());
    dispatch(FetchTestimonies());
    dispatch(FetchComp());
  }, [dispatch]);

  const onResize = () => {
    dispatchCloseSidebar();
    if (window.innerWidth < 1700) {
      const rel = window.innerWidth > 1400 ? window.innerWidth : 1400;
      const ratio = (1700 - rel) / (1700 - 1400);
      const offsetRight = 180 * ratio;
      setOffsetRight(offsetRight);
    } else {
      setOffsetRight(0);
    }
  };

  const onScroll = () => {
    if (window.scrollY > 100) {
      setIsOffTop(true);
    } else {
      setIsOffTop(false);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper isOffTop={isOffTop} className={absolute ? "absolute" : ""}>
      <div className="contact container">
        <span>
          <a href={`mailto:${company.email}`}>{company.email}</a>
          <a href={`tel:${company.phone}`}>{company.phone}</a>
        </span>
      </div>
      <div className="navbar-wrapper">
        <div className="container content">
          <Link to="/">
            <Logo />
          </Link>
          <button
            type="button"
            className="nav-open-btn"
            onClick={dispatchOpenSidebar}
          >
            <IoMenuOutline />
          </button>
          <nav className={isSidebarOpen ? "navbar active" : "navbar"}>
            <button
              type="button"
              className="nav-close-btn"
              onClick={dispatchCloseSidebar}
            >
              <IoMdClose />
            </button>
            <MainNavLinks />
          </nav>
        </div>
      </div>
    </Wrapper>
  );
};

export default MainNavbar;

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background-color: var(--white);
  transition: var(--transition);
  box-shadow: ${(props) => (props.isOffTop ? "var(--shadow-1)" : "none")};

  .contact {
    text-align: right;
    padding: 0.5rem 0;
    transition: var(--transition);

    a {
      margin-left: 1rem;
    }
  }

  .navbar-wrapper {
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0;
    }
  }

  .nav-open-btn,
  .nav-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: var(--textColor);
    transition: var(--transition);
  }

  .navbar {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: var(--white);
    box-shadow: var(--shadow-2);
    transition: all 0.7s cubic-bezier(0.86, 0, 0.07, 1);

    top: -100vh;
    visibility: hidden;
    pointer-events: none;

    .nav-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
      z-index: 30;
    }

    .nav-list {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 40px 25px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      font-size: 16px;
      animation: preventShowOnResize 0.3s step-end;

      .nav-item {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.3s linear,
          transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;

        &.has-dropdown svg {
          display: none;
        }

        & > a:not(.btn) {
          display: block;
          height: 100%;
          padding: 1rem 0;
          font-size: 20px;
          transition: var(--transition);
        }

        .dropdown {
          display: none;
        }

        &.book-now {
          margin-top: 2rem;
          .btn {
            width: 100%;
          }
        }
      }
    }

    &.active {
      top: 0;
      visibility: visible;
      pointer-events: all;

      .nav-list .nav-item {
        opacity: 1;
        transform: translateY(0);

        &:nth-of-type(1) {
          transition-delay: 250ms;
        }
        &:nth-of-type(2) {
          transition-delay: 300ms;
        }
        &:nth-of-type(3) {
          transition-delay: 350ms;
        }
        &:nth-of-type(4) {
          transition-delay: 400ms;
        }
        &:nth-of-type(5) {
          transition-delay: 450ms;
        }
        &:nth-of-type(6) {
          transition-delay: 500ms;
        }
        &:nth-of-type(7) {
          transition-delay: 550ms;
        }
      }
    }
  }

  &.absolute {
    background-color: ${(props) =>
      props.isOffTop ? "var(--white)" : "transparent"};
    .contact,
    .nav-open-btn {
      color: ${(props) => (props.isOffTop ? "inherit" : "var(--white)")};
    }
  }

  @media (min-width: 992px) {
    .nav-open-btn,
    .nav-close-btn {
      display: none;
    }
    .navbar {
      position: static;
      padding: 0;
      width: auto;
      background-color: transparent;
      box-shadow: none;
      transition: none;
      visibility: visible;
      pointer-events: all;

      .nav-list {
        position: static;
        padding: 0;
        flex-direction: row;
        overflow: visible;
        animation: none;
        .nav-item {
          position: relative;
          transition: none;
          opacity: 1;
          transform: translateY(0);
          transition: var(--transition);
          & > a:not(.btn) {
            display: flex;
            align-items: center;
            font-size: 16px;
            padding: 0 1.25rem;
          }

          &.has-dropdown {
            position: relative;

            svg {
              display: block;
              position: absolute;
              top: 50%;
              right: 2px;
              transition: transform 0.2s ease-in-out;
              transform: translateY(-50%);
              font-size: 0.75rem;
            }

            /* &::after {
              z-index: -99;
              content: "";
              position: absolute;
              width: 0;
              height: 0;
              margin-left: 0;
              top: calc(100% - 11px);
              left: 50%;
              box-sizing: border-box;
              border: 7px solid #f8f8f8;
              border-color: #f8f8f8 #f8f8f8 transparent transparent;
              transform: rotate(-45deg) translateX(-7px);
              transition: var(--transition);

              opacity: 0;
              pointer-events: none;
            } */
          }

          .dropdown {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 2rem;
            position: absolute;
            top: 90%;
            left: 0;
            width: max-content;
            padding: 1rem 1.2rem;
            background-color: ${colors.white};
            border-radius: 0 0 var(--borderRadius) var(--borderRadius);
            box-shadow: var(--shadow-2);
            transition: var(--transition);

            opacity: 0;
            pointer-events: none;

            span {
              font-family: var(--headingFont);
              font-size: 1rem;
              color: #666;
            }

            ul {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;

              li {
                padding: 0 1rem;

                a {
                  display: inline-block;
                  padding: 0.5rem 0;
                }
              }
            }
          }

          /* &.reverse-dropdown .dropdown {
            left: auto;
            right: 0;
            grid-template-columns: 1fr auto;

            span {
              grid-column: 2;
            }
            ul {
              grid-column: 1;
              grid-row: 1;
            }
          } */

          &:hover {
            .dropdown,
            &::after {
              opacity: 1;
              pointer-events: all;
            }
            &.has-dropdown svg {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
        .nav-item.book-now {
          margin-top: 0;
          margin-left: 1.25rem;
        }
      }
    }

    &.absolute {
      .navbar .nav-list .nav-item > a:not(.btn) {
        color: ${(props) => (props.isOffTop ? "inherit" : "var(--white)")};
      }
    }
  }

  @keyframes preventShowOnResize {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
