import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "blogapi/api/v1/post";
const INITIALSTATE = {
  data: [],
  isSuccess: false,
  errMessage: "",
  loading: true,
};

export const FetchBlogArticles = createAsyncThunk(
  "blog/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchBlog = createAsyncThunk(
  "blog/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args.id}`, args.value);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const blogReducer = createSlice({
  name: "bookings",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchBlogArticles.pending]: (state) => {
      state.loading = true;
    },
    [FetchBlogArticles.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchBlogArticles.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchBlog.pending]: (state) => {
      state.loading = true;
    },
    [FetchBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchBlog.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
  },
});

export default blogReducer.reducer;
