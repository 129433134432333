import { IoAddOutline, IoRemoveOutline } from "react-icons/io5";
import { Text, Title } from "../../components/typography";
import { colors, responsiveness } from "../../assets/styles/parameters";

import styled from "styled-components";
import { useState } from "react";

export const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (id) => {
    setActiveIndex(id);
  };

  const renderedItems = data.map((item, id) => {
    const activeItem = id === activeIndex ? "active" : "";

    return (
      <BaseAccordion key={id}>
        <AccordionTitle onClick={() => onTitleClick(id)}>
          <Title number={5}>{item.question}</Title>

          {activeItem ? <IoRemoveOutline /> : <IoAddOutline />}
        </AccordionTitle>
        <AccordionContent className={activeItem}>
          <Text text={item.answer} />
        </AccordionContent>
      </BaseAccordion>
    );
  });

  return <div>{renderedItems}</div>;
};

export default Accordion;

const BaseAccordion = styled.div`
  padding: 0;
  border-bottom: 0.7px solid ${colors.gray100};
  border-width: min-content;
  border-radius: 5px;

  .active {
    display: block;
  }
`;

const AccordionTitle = styled.div`
  display: flex;
  font-size: 1.5rem;
  padding: 1rem 1rem;
  justify-content: space-between;
  border-bottom: 0.7px solid ${colors.gray400};

  h5 {
    margin: 0;
    width: 90%;
  }

  svg {
    padding: 0;
    margin: 0 1rem 0;
    background-color: ${colors.greenDark};
    color: white;
    border-radius: 5px;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 1rem 0rem;
  }
`;

const AccordionContent = styled.div`
  display: none;
  padding: 1rem 1rem;

  p {
    margin: 1rem 0;
    padding: 0;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    padding: 1rem 0rem;
  }
`;
