import Wrapper from "./CounterBanner.styled";

const CounterBanner = ({ data, ...props }) => {
  const fiveStarCount = data.filter((s) => s && s.rating === 5).length;
  const totalTestimonyCount = data.length;

  return (
    <Wrapper {...props} className="spaced-section">
      <div className="container grid">
        <div>
          <span className="title-text count">{fiveStarCount}</span>
          <p className="big-text">5 Star Rating</p>
        </div>
        <div>
          <span className="title-text count">{totalTestimonyCount}</span>
          <p className="big-text">Testimonies from Happy Clients</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default CounterBanner;
