import { Container, Grid, Section } from "../../../components/layout";

import { CardCTA } from "../../../components/cards";
import { IoLeafOutline } from "react-icons/io5";
import { Title } from "../../../components/typography";
import parse from "html-react-parser";
import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

const ServiceDescription = ({ service, ...props }) => {
  const { title, description, subDescription } = service;

  return (
    <Wrapper {...props} list={<IoLeafOutline />}>
      <Container width="70" margin="auto">
        <Title>{title}</Title>
      </Container>
      <Grid col="l15">
        <div>
          <>{parse(`${description}`)}</>
          {subDescription !== undefined ||
            (subDescription !== "undefined" && (
              <>{parse(`${subDescription}`)}</>
            ))}
        </div>
        <CardCTA
          title="Ready to transform your property"
          description="Leave your landscaping and cleaning needs to us."
          path="/book-now"
          pathText="Request an Estimate"
        />
      </Grid>
    </Wrapper>
  );
};

export default ServiceDescription;

const Wrapper = styled(Section)`
  h3 {
    margin-bottom: 3rem;
    text-align: center;
  }

  & > div:nth-child(2) {
    gap: 6rem;

    & > div:first-child {
      width: 90%;

      ul {
        padding-left: 30px;
        li {
          list-style: circle;
          margin-bottom: 0.6rem;

          svg {
            color: var(--success-500);
            font-size: 2rem;
          }

          p {
            margin: 0;
            margin-left: 0.75rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    & > div:nth-child(2) {
      padding: 0 2rem;
    }
  }
`;
