import { Container, Grid, Section } from "../../../components/layout";
import { Text, Title } from "../../../components/typography";

import { Link } from "react-router-dom";
import styled from "styled-components";

export const ImagesList = ({ data }) => {
  return (
    <Wrapper>
      <Container>
        <Title number={4}>View images from our recent projects</Title>
        <Grid>
          {data &&
            data !== null &&
            data.map((image, i) => {
              const { id, media, name, description } = image;
              const url =
                media !== null &&
                media.mediaFiles &&
                media.mediaFiles[0] &&
                media.mediaFiles[0].url;

              return (
                <Link key={i} to={`/gallery/${id}`}>
                  <img src={url} alt={description} />
                  <Text text={name} />
                </Link>
              );
            })}
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default ImagesList;

const Wrapper = styled(Section)`
  h4 {
    margin-bottom: 4rem;
  }

  a {
    text-align: center;

    p {
      margin-top: 0.5rem;
    }
  }
`;
