import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "orgapi/api/v1/partners";
const INITIALSTATE = {
  isSuccess: false,
  errMessage: "",
  loading: true,
  data: [],
};

export const FetchPartners = createAsyncThunk(
  "partners/fetches",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(BASEPATH);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const FetchPartner = createAsyncThunk(
  "partner/fetch",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.get(`${BASEPATH}/${args}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  }
);

export const partnerSlice = createSlice({
  name: "partners",
  initialState: INITIALSTATE,
  extraReducers: {
    [FetchPartners.pending]: (state) => {
      state.loading = true;
    },
    [FetchPartners.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchPartners.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
    [FetchPartner.pending]: (state) => {
      state.loading = true;
    },
    [FetchPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true;
    },
    [FetchPartner.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.errMessage = action.payload;
    },
  },
});

export default partnerSlice.reducer;
