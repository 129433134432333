import styled from "styled-components";

const ContactBanner = ({ img, ...props }) => {
  return (
    <Wrapper {...props}>
      <img src={img} alt="Font yard curb appeal." />
    </Wrapper>
  );
};

export default ContactBanner;

const Wrapper = styled.div`
  img {
    height: 200px;
    object-fit: cover;
  }
`;
