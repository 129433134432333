import { useDispatch, useSelector } from "react-redux";

import { FetchPartner } from "../../redux/PartnerSlice";
import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import PartnersGrid from "../../elements/partners/PartnersGrid";
import { useEffect } from "react";

export const Partnerships = () => {
  const { data } = useSelector((state) => state.partners);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchPartner());
  }, [dispatch]);

  return (
    <>
      <Meta title="Partner" />
      <PageHeader title="Partners" />
      <PartnersGrid data-aos="fade-up" partners={data} />
    </>
  );
};

export default Partnerships;
