import { Container, Grid, Section } from "../../components/layout";
import { Text, Title } from "../../components/typography";

import { Link } from "react-router-dom";
import parse from "html-react-parser";
import styled from "styled-components";

const PartnersGrid = ({ partners, ...props }) => {
  return (
    <Wrapper {...props}>
      <Container>
        {partners.map((partner, i) => {
          const { media, type, company } = partner;
          return (
            <Grid className="item" key={i}>
              <img
                src={media && media.mediaFiles && media.mediaFiles[0].url}
                alt={type}
                width={530}
                height={390}
              />
              <div className="content">
                <Title number={3}>{type}</Title>
                <Text
                  as="div"
                  scale="medium"
                  text={<>{parse(`${company}`)}</>}
                />
                <Link
                  to={`/partners/${type.toLowerCase().replace(/\s+/g, "-")}`}
                  className="underlined"
                  state={{ partner: partner }}
                >
                  Learn more
                </Link>
              </div>
            </Grid>
          );
        })}
      </Container>
    </Wrapper>
  );
};

export default PartnersGrid;

const Wrapper = styled(Section)`
  & > div {
    .item {
      margin: 5rem 0 8rem;
    }
  }
`;
