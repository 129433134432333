import Footer from "./footer.js/Footer";
import MainNavbar from "./navigation/MainNavbar";
import { Path } from "../../routes/Paths";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const router = useLocation();
  const isAbsoluteNavbar = Path.some((page) => {
    return router.pathname === page.path;
  });

  return (
    <>
      <MainNavbar absolute={isAbsoluteNavbar} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
