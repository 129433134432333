import { useDispatch, useSelector } from "react-redux";

import { FetchServices } from "../../../redux/ServiceSlice";
import { Link } from "react-router-dom";
import { Title } from "../../../components/typography";
import Wrapper from "./ServicesList.styled";
import altImg from "../../../assets/imgs/corporate_ext.jpg";
import { useEffect } from "react";

const SingleService = ({ service }) => {
  const { media, title } = service;
  const serviceName = title.toLowerCase().replace(/\s+/g, "-");

  return (
    <div className="service">
      <Link
        to={`/services/${serviceName}`}
        state={{ service: service, id: service.id }}
      >
        <img
          src={
            media !== null &&
            media.mediaFiles &&
            media.mediaFiles[0] &&
            media.mediaFiles[0].url
              ? media.mediaFiles[0].url
              : altImg
          }
          alt={title}
          width={330}
          height={280}
        />
        <Title number={6} className="title">
          {title}
        </Title>
      </Link>
    </div>
  );
};

const ServicesList = ({ ...props }) => {
  const { data } = useSelector((state) => state.services);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchServices());
  }, [dispatch]);

  return (
    <Wrapper {...props} className="spaced-section">
      <div className="container">
        <div className="services-list">
          {data
            .filter((s, i) => s && i <= 3)
            .map((service) => (
              <SingleService key={service.id} service={service} />
            ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default ServicesList;
