import { Meta } from "../../components/meta";
import { PageHeader } from "../../components/layout";
import PartnerDescription from "../../elements/partners/PartnerDescription";
import { useLocation } from "react-router-dom";

export const PartnershipDetailPage = () => {
  const location = useLocation();
  const partner = location.state.partner;
  return (
    <>
      <Meta title="Partner" />
      <PageHeader
        title={partner.type}
        backgroundImage={
          partner.media &&
          partner.media.mediaFiles &&
          partner.media.mediaFiles[0].url
        }
      />
      <PartnerDescription data-aos="fade-up" description={partner.company} />
    </>
  );
};

export default PartnershipDetailPage;
