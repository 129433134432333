import { lazy } from "react";
export { ContactPage } from "./contact/ContactPage";
export { Services } from "./services/Services";
export { ServiceDetailPage } from "./services/ServiceDetail";
export { Partnerships } from "./partnership/Partnerships";
export { PartnershipDetailPage } from "./partnership/PartnershipDetail";
export { Page404 } from "./404";
export { TestimonialPage } from "./testimony/TestimonyPage";
export { TestimonyCreate } from "./testimony/TestimonyCreate";
export { ThankYouPage } from "./bookings/ThankYouPage";
export { Gallery } from "./gallery/Gallery";
export { GalleryDetailPage } from "./gallery/GalleryDetail";
export { FAQPage } from "./about/FAQPage";

export const Home = lazy(() => import("./home/HomePage"));
export const AboutPage = lazy(() => import("./about/AboutPage"));
export const ServicesPage = lazy(() => import("./services"));
export const PartnershipPage = lazy(() => import("./partnership"));
export const Testimony = lazy(() => import("./testimony/TestimonyPage"));
export const BookingPage = lazy(() => import("./bookings/BookingPage"));
export const GalleryPage = lazy(() => import("./gallery"));
