import { FaArrowUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Title } from "../../typography";
import { company } from "../../../redux/data";
import styled from "styled-components";

const Footer = () => {
  return (
    <Wrapper>
      <div className="container content">
        <div className="grid">
          <div>
            <Title number={4}>Extreme Clean Environmental</Title>
            <address>
              <span>
                <p>{company.address.street}</p>
                <p>{company.address.citystate}</p>
              </span>
              <span>
                <a href={`tel:${company.phone}`}>{company.phone}</a>
              </span>
              <span>
                <a href={`mailto:${company.email}`}>{company.email}</a>
              </span>
            </address>
            {/* <ul className="social-list">
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IoLogoTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BiWorld />
                </a>
              </li>
            </ul> */}
          </div>
          <ul className="links-list">
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/book-now">Request Estimate</Link>
            </li>
            <li>
              <Link to="/testimonials">Testimonials</Link>
            </li>{" "}
            <li>
              <Link to="/testimonials/submit">Submit Testimonial</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/gallery">Gallery</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/partners">Partners</Link>
            </li>
          </ul>
          {/* <div className="gallery">
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
            <img src="/images/gardener.jpg" alt="Gardener" />
          </div> */}
        </div>

        <div className="copyright">
          <small>
            Copyright {new Date().getFullYear()} - Extreme Clean Environmental
            Services, LLC
            {/* | <Link to="/policy/privacy">Privacy Policy</Link> |{" "}
            <Link to="/policy/terms">Terms of Services</Link> */}
          </small>
          <button
            type="button"
            className="go-top"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <FaArrowUp />
          </button>
        </div>
      </div>
    </Wrapper>
  );
};
export default Footer;

const Wrapper = styled.footer`
  background-color: var(--white);
  color: var(--black);
  padding-top: 5rem;
  padding-bottom: 1rem;

  img {
    border-radius: 5px;
  }

  address span {
    display: block;
    font-style: normal;
    margin-bottom: 0.5rem;

    p {
      font-style: normal;
      margin-bottom: 0;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    column-gap: 1rem;
    row-gap: 4rem;
    margin-bottom: 4rem;
  }

  .social-list {
    display: inline-flex;

    a {
      display: flex;
      align-items: center;
      background-color: var(--primary-500);
      border-radius: 5px;
      font-size: 1.3rem;
      padding: 0.25rem;
      margin-right: 0.5rem;
      color: var(--white);

      &:hover {
        color: var(--secondary-100);
        background-color: var(--primary-600);
      }
    }
  }

  .links-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
  }

  .gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 0 auto;
  }

  .copyright {
    display: flex;
    justify-content: space-between;
    font-weight: 300;

    small {
      padding-right: 1rem;
    }

    .go-top {
      min-width: 25px;
      min-height: 25px;
      font-size: 1.3rem;
      color: var(--primary-500);

      &:hover {
        color: var(--primary-500);
      }
    }
  }

  @media (min-width: 576px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }

    .links-list {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .gallery {
      margin-right: 0;
      margin-left: auto;
    }
  }
  @media (min-width: 992px) {
    .grid {
      grid-template-columns: 1.5fr 1fr 300px;
      margin-bottom: 6rem;
    }
    .links-list {
      grid-row-start: auto;
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }
`;
