import AppSlice from "./AppSlice";
import BlogReducer from "./BlogSlice";
import BookingReducer from "./BookingSlice";
import ContactReducer from "./ContactSlice";
import CredReducer from "./CompCredSlice";
import FAQReducer from "./FAQSlice";
import GalleryReducer from "./GallerySlice";
import OrgReducer from "./CompanySlice";
import PartnerReducer from "./PartnerSlice";
import ServicesReducer from "./ServiceSlice";
import StaffReducer from "./StaffSlice";
import TestimonyReducer from "./TestimonySlice";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  blog: BlogReducer,
  bookings: BookingReducer,
  company: OrgReducer,
  cred: CredReducer,
  contact: ContactReducer,
  partners: PartnerReducer,
  services: ServicesReducer,
  staff: StaffReducer,
  testimony: TestimonyReducer,
  faq: FAQReducer,
  gallery: GalleryReducer,
  app: AppSlice,
});
