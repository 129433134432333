import styled from "styled-components";

const Wrapper = styled.section`
  .big-text {
    max-width: 580px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    img {
      border-radius: var(--borderRadius);
    }
  }

  @media (min-width: 576px) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (min-width: 992px) {
    .grid {
      gap: 4rem;
    }
  }
`;

export default Wrapper;
