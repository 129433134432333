import { IoStar, IoStarOutline } from "react-icons/io5";

import Wrapper from "./TestimonialsGrid.styled";
import img from "../../../assets/imgs/MANICURED_FLOWER_BED.jpg";

const SingleTestimonial = ({ testimonial }) => {
  const { client, company, rating, title, message } = testimonial;

  const fullStars = Array.from({ length: rating }).map((_, i) => (
    <IoStar key={i} />
  ));
  const emptyStars = Array.from({ length: 5 - rating }).map((_, i) => (
    <IoStarOutline key={i} />
  ));

  return (
    <div className="testimonial">
      <div className="body">
        <div className="stars">
          {fullStars}
          {emptyStars}
        </div>
        <p className="title-text">{title}</p>
        <p className="big-text message">{message}</p>
      </div>
      <div className="footer">
        <div className="profile-picture">
          <img src={img} alt={client} width={42} height={42} />
        </div>

        <span className="user">
          {client}
          {company && `, ${company}`}
        </span>
      </div>
    </div>
  );
};

const TestimonialsGrid = ({ testimonials, ...props }) => {
  return (
    <Wrapper {...props} className="spaced-section">
      <div className="container grid">
        {testimonials.map((testimonial) => (
          <SingleTestimonial key={testimonial.id} testimonial={testimonial} />
        ))}
      </div>
    </Wrapper>
  );
};

export default TestimonialsGrid;
