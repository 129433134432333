import { Link } from "react-router-dom";
import { Title } from "../../components/typography";
import { colors } from "../../assets/styles/parameters";
import styled from "styled-components";

export const CardItem = ({
  version,
  img,
  imgDes,
  title,
  text,
  status,
  path,
  children,
  state,
  ...otherProps
}) => {
  switch (version) {
    case "1":
      return (
        <BaseListItem1 {...otherProps}>
          <Title number={6}>{title}</Title>
          <>{children}</>
        </BaseListItem1>
      );
    case "2":
      return (
        <BaseListItem2 {...otherProps}>
          <div>
            <img src={img} alt={imgDes} />
            <Link to={path} state={state}>
              {text}
            </Link>
          </div>
        </BaseListItem2>
      );
    case "3":
      return (
        <BaseListItem3 {...otherProps}>
          <div>
            <Link to={path} state={state}>
              {text}
            </Link>
          </div>
        </BaseListItem3>
      );
    case "4":
      return (
        <BaseListItem4 {...otherProps}>
          <div>{text}</div>
        </BaseListItem4>
      );
    default:
      return <BaseListItem {...otherProps}>{children}</BaseListItem>;
  }
};

const BaseListItem = styled.li`
  color: ${colors.black};
  border: 1px solid ${colors.gray100};
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  padding: 0.5rem 1rem;

  &:last-child {
    border-bottom: 1px solid ${colors.gray100};
  }

  img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 0.7rem;
  }

  h6,
  a {
    margin: 0;
    padding: 0;
    color: ${colors.gray600};
  }
`;

const BaseListItem1 = styled(BaseListItem)`
  /* display: flex;
  align-items: center; */

  & > div {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    h6 {
      margin: 0;
      padding: 0;
    }
  }
`;

const BaseListItem2 = styled(BaseListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }

  div:last-child {
    align-self: flex-end;
  }
`;

const BaseListItem3 = styled(BaseListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
  }

  div:last-child {
    align-self: flex-end;
  }
`;

const BaseListItem4 = styled(BaseListItem)`
  &:hover {
    background-color: ${colors.white};
  }
`;
