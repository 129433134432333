import { responsiveness } from "../../../assets/styles/parameters";
import styled from "styled-components";

const Section = ({ children, ...otherProps }) => {
  return <BaseSection {...otherProps}>{children}</BaseSection>;
};

export default Section;

const BaseSection = styled.section`
  padding: 5rem 4rem;

  @media screen and (min-width: ${responsiveness.maxWidth}) {
    width: ${responsiveness.maxWidth};
    margin: 0 auto;
  }

  @media screen and (max-width: ${responsiveness.mobile1}) {
    width: 100%;
    padding: 1.5rem 1rem;
  }
`;
