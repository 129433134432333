import styled from "styled-components";

const Wrapper = styled.section`
  text-align: center;

  .services-list {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 4rem;
    margin: 5rem auto;

    .service {
      max-width: 330px;
      margin: 0 auto;

      img {
        border-radius: var(--borderRadius);
        width: 330;
        height: 280;
      }

      .title {
        margin-top: 0.75rem;
      }

      .excerpt {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 576px) {
    .services-list {
      grid-template-columns: 1fr 1fr;

      .service:last-of-type {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
  @media (min-width: 992px) {
    .services-list {
      grid-template-columns: 1fr 1fr 1fr;

      .service:last-of-type {
        grid-column-start: 3;
        grid-column-end: 4;
      }
    }
  }
`;

export default Wrapper;
