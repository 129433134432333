import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { eces } from "../api/AxiosAPI";

const BASEPATH = "contactapi/api/v1/contact/";
const INITIALSTATE = {
  isSuccess: false,
  loading: true,
  errMessage: "",
  data: {},
};

export const CreateMessage = createAsyncThunk(
  "contact/messages/create",
  async (args, { rejectWithValue }) => {
    try {
      const response = await eces.post(`${BASEPATH}`, args);
      return response.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

export const contactSlice = createSlice({
  name: "contacts",
  initialState: INITIALSTATE,
  reducers: {
    resetState: () => INITIALSTATE,
  },
  extraReducers: {
    [CreateMessage.pending]: (state) => {
      state.loading = true;
    },
    [CreateMessage.fulfilled]: (state, action) => {
      state.loading = false;
      state.isSuccess = true;
      state.data = action.payload;
      state.error = "";
    },
    [CreateMessage.rejected]: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.data = {};
      state.errMessage = action.payload;
    },
  },
});

export const { resetState } = contactSlice.actions;
export default contactSlice.reducer;
