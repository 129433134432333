import { Span, Text } from "../typography";

import { BaseLabel } from "../forms/Inputs";
import ReactStars from "react-rating-stars-component";

export const CustomRatingInput = (props) => {
  const { input, label, entity, ...otherProps } = props;
  return (
    <div {...otherProps}>
      <BaseLabel>
        <Text
          text={
            <>
              {label}
              {input.value && <Span color="green"> - {input.value}</Span>}{" "}
            </>
          }
        />
        {entity && <Span>{entity}</Span>}
      </BaseLabel>
      <ReactStars
        count={5}
        size={40}
        a11y={true}
        value={input.value || 0}
        onChange={input.onChange}
        edit={true}
        isHalf={true}
      />
    </div>
  );
};
